export enum Anchors {
  // index
  About = "about",
  Function = "function",
  Sponsors = "sponsors",
  FAQ = "faq",
  Kontaktperson = "kontaktperson",
  // imprint
  Kontakt = "contact",
  VerweiseUndLinks = "verweise-und-links",
  Datenschutz = "data-protection",
  Cookies = "cookies",
  AbwicklungVonVertraegen = "abwicklung-von-verträgen",
  Kontaktaufnahme = "kontaktaufnahme",
  Datensicherheit = "data-security",

}
